import Vue from 'vue';
import store from './store';
import VueCookies from 'vue-cookies';
import VueGapi from 'vue-gapi';
import {firestorePlugin} from 'vuefire';
import App from './App.vue';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

Vue.use(VueCookies);

Vue.use(firestorePlugin);

Vue.use(VueGapi, {
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'https://www.googleapis.com/auth/youtube',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest',
  ],
});

new Vue({
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
