import Vue from 'vue';
import Vuex from 'vuex';
import {VueGapi} from 'vue-gapi';

Vue.use(Vuex);

export interface User {
  name?: string;
  googleId?: string;
  youtubeChannelId?: string;
}

export interface StoreState {
  currentUser?: User;
}

export default new Vuex.Store<StoreState>({
  state: {},
  mutations: {
    setCurrentUser(state: StoreState, user?: User): void {
      state.currentUser = user;
    },
  },
  actions: {
    async updateCurrentUser({commit}) {
      const $gapi = Vue.prototype.$gapi as VueGapi;
      if ($gapi.isAuthenticated()) {
        const gapi = await $gapi.getGapiClient();
        const response = await gapi.client.youtube.channels.list({
          part: 'id',
          mine: true,
        });
        const channelId =
          response.result.items && response.result.items.length > 0
            ? response.result.items[0].id
            : undefined;
        const userData = $gapi.getUserData();
        const user: User = {
          name: userData !== null ? userData.fullName : undefined,
          googleId: userData !== null ? userData.id : undefined,
          youtubeChannelId: channelId,
        };
        commit('setCurrentUser', user);
      } else {
        commit('setCurrentUser', undefined);
      }
    },
  },
});
