












import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {
    AdminBar: () => import('@/components/AdminBar.vue'),
    PasswordChecker: () => import('@/components/PasswordChecker.vue'),
    PlaylistTable: () => import('@/components/PlaylistTable.vue'),
  },
})
export default class App extends Vue {
  private loggedIn = false;

  private created(): void {
    this.loggedIn = this.$cookies.isKey('logged_in');
    this.$store.dispatch('updateCurrentUser');
  }

  private login(): void {
    this.loggedIn = true;
    this.$cookies.set(
      'logged_in',
      'jo',
      '7d',
      undefined,
      undefined,
      false,
      'Strict'
    );
  }
}
